footer {
    background: #f7f7f7;
    margin: 0; padding:0;
}
footer .navbar {
    padding-bottom: 0px;
}

footer a{
    color: #6c757d;
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    font-size: 12pt;
    text-decoration: none;
}

footer a:hover {
    color: #1B1B3FFF;
}
@media only screen and (max-width: 1024px) {
    footer {
        width: 100vw;
    }
}