header .nav-link {
    color: #FFF;
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    font-size: 12pt;
}
header .container {
    width: 1296px;
}

header a.nav-link:hover {
    color: #ececec;
}

header ul.navbar {
    margin:0;
    padding:0;
}
header .navbar {
    margin:0;
}
header .mobile-nav-button {
    width: 32px;
    height: 32px;
    color: #FFFFFF;
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    position: absolute;
    top: 1vh;
    right: 5vw;
}
#navBarToggle { display: none; }

@media only screen and (max-width: 1024px) {
    #navBarToggle {
        position: fixed;
        left: 0; top: 12vw;
        z-index: 10000;
        float: left;
        width: 100%;
    }
    header ul {
        margin: 0; padding:0;
        width: 100vw;
        background: #FFFFFF;
        border-top: #ececec 1px solid;
    }
    .nav-link {
        color: #1a1a1a!important;
        font-family: 'Roboto Condensed', sans-serif;
    }
    .nav-link:hover {
        text-decoration: underline;
    }
    header ul li {
        width: 100vw;
        text-align: center;
    }
    header ul li:nth-child(odd) {
        background: #ececec;
    }
}