
#CodeFound, #CodeNotFound {  display: none; }
.OAuthProvider {  padding: 1vw; }
.OAuthProvider img { width: auto; height: 8vh; }
.RedemptionFooter {  margin: 3vw;  margin-top: 10vh; }

.OAuthProvider.DiscordOauth {
    background: #23272a url("/public/assets/Discord-Logo+Wordmark-Color.png") no-repeat top center;
    background-size: 85%;
    width: 100%;
    height: auto;
    padding: 2vw;
}
.OAuthProvider.YoutubeOauth {
    background: #212121 url("/public/assets/yt_logo_rgb_dark.png") no-repeat center top;
    background-size: 70%;
    background-origin: content-box;
    width: 100%;
    height: auto;
}
.OAuthProvider.TwitchOauth {
    background: #000000 url("/public/assets/TwitchExtrudedWordmarkPurple.png") no-repeat center center;
    background-size: 70%;
    background-origin: content-box;
    width: 100%;
    height: auto;
}
.noUnderline {
    text-decoration: none;
}

.oAuth-Action {
    color: #1a1a1a;
    font-family: 'Roboto Condensed', sans-serif;
    text-decoration: none;
    background: #FFFFFF;
    position: relative;
    top:3.5vh; left: 60%;
    border-radius: 5px;
    font-size: 16pt;
    width: 40%;
}

/*
    Invite Redemption Below
 */

.InviteRedemption h1 {
    font-size: 18pt;
    font-family: 'IBM Plex Sans Arabic', sans-serif;
}
.InviteRedemption .invite-form {
    margin: 0 auto;
}
.InviteRedemption .inviteField {
    height: 4vh;
    font-size: 20pt;
    text-align: center;
    margin: 1vh;
}
.redeem-invite-icon {
     background-image: url("Images/Icons/members-icon.png");
     background-position: left;
     background-repeat: no-repeat;
     width: 20vw;
     height: 151px;
     position: relative;
     right:.48vw;
     bottom:.1vh;

     color: #f7f7f7;
     text-align: right;
     font-family: "Special Elite";
     font-size: 24pt;
     padding:30px 0px;
}


/*
    MineCraft Redemption Below
 */

.mcRedemption h1 {
    font-size: 18pt;
    font-family: 'IBM Plex Sans Arabic', sans-serif;
}
.mcRedemption .mc-form {
    margin: 0 auto;
}
.mcRedemption .mcField {
    height: 4vh;
    font-size: 20pt;
    text-align: center;
    margin: 1vh;
}
.redeem-mc-icon {
    background-image: url("Images/Icons/quests-icon.png");
    background-position: left;
    background-repeat: no-repeat;
    width: 20vw;
    height: 151px;
    position: relative;
    right:.48vw;
    bottom:.1vh;

    color: #f7f7f7;
    text-align: right;
    font-family: "Special Elite";
    font-size: 24pt;
    padding:30px 0px;
}

/*
    Creator Redemptions
 */

.creatorRedemption h1 {
    font-size: 18pt;
    font-family: 'IBM Plex Sans Arabic', sans-serif;
}
.creatorRedemption .mc-form {
    margin: 0 auto;
}
.creatorRedemption .mcField {
    height: 4vh;
    font-size: 20pt;
    text-align: center;
    margin: 1vh;
}
.redeem-creator-icon {
     background-image: url("Images/Icons/streams-icon.png");
     background-position: left;
     background-repeat: no-repeat;
     width: 20vw;
     height: 151px;
     position: relative;
     right:.48vw;
     bottom:.1vh;

     color: #f7f7f7;
     text-align: right;
     font-family: "Special Elite";
     font-size: 24pt;
     padding:30px 0px;
 }