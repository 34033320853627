@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Special+Elite&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
.jumbotron {
    background: #615dfa;
    border-bottom: 5px solid #524fdc;
}
.jumbotron-invite {
    background: #f7f7f7;
    border-bottom: 5px solid #e8e8e8;
    margin-bottom: 1vh;
}

.hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(51,51, 51, 0.8)), url("landing.jpg"); */
    background-image: linear-gradient(to left, transparent, rgba(97, 93, 250, 0.99) 100%), linear-gradient(to right, transparent, #615dfa 100%),  url("Images/landing.jpg");
    /* Set a specific height */
    height: 500px;

    /* Position and center the image to scale nicely on all screens */
    background-position: bottom left;
    background-repeat: no-repeat;
}

.invited-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(51,51, 51, 0.8)), url("landing.jpg"); */
    background-image: linear-gradient(to left, transparent, #f7f7f7 100%), linear-gradient(to right, transparent, #f7f7f7 100%), url("Images/shutterstock_2053519382.png");
    /* Set a specific height */
    height: 260px;

    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-size: cover;
    width: 100%;
}

.invited-image .title {
    position: relative;
    top: 13vh;
    width: 50%;
    text-align: left;
    color: #615dfa;
    transform: rotate(0deg);

    font-family: "Special Elite", serif;
    font-size: 24pt;
    padding: 5px;
    padding-left: 3vw;
}

@media only screen and (max-width: 1024px) {

    .invited-image .title {
        position: relative;
        top: 1vh;
        width: 100vw;
    }
}
/* Place text in the middle of the image */
.hero-text {
    position: relative;
    top:2vh;
    text-align: center;
    color: #f7f7f7;
    font-family: 'Special Elite', cursive;
    font-size: 22pt;
}

.hero-button {
    position: relative;
    top:20vh;
    right:10vw;
}
@media only screen and (max-width: 1024px) {
    .hero-button {
        position: relative;
        top: 15vh;
        right: 0;
    }
}
.hero-button .btn {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    color: #1a1a1a;
    font-size: 14pt;
}

.landing-info {
    font-family: 'Special Elite', cursive;
    font-size: 6.5rem;
    font-weight: 900;
}

.card { cursor: pointer; }
.card a {
    font-family: "Special Elite", serif;
    font-size: 32pt;
    color: #f7f7f7;
    text-decoration: none;
}

.bg-forum {
    background-image: url("Images/forum-screenshot.png");
    background-position: left top;
    background-size: cover;
    height: 275px;
    box-shadow: 0 0 25px 10px rgba(26, 26, 26, 0.42) inset;
}
.bg-forum:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, rgba(26, 26, 26, 0.9), #1a1a1a);
    opacity: .7;
}

.bg-shop {
    background-image: url("Images/shopping-online-button-SBI-300079780.jpg");
    background-position: center;
    background-size: cover;
    height: 275px;
    cursor: pointer;
    box-shadow: 0 0 25px 10px rgba(26, 26, 26, 0.42) inset;
}
.bg-shop:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, rgba(26, 26, 26, 0.83), #1a1a1a);
    opacity: .7;
}

.bg-map {
    background-image: url("Images/huge_2022-03-28_18.57.53.png");
    background-position: center;
    background-size: cover;
    height: 275px;
    cursor: pointer;
    box-shadow: 0 0 25px 10px rgba(26, 26, 26, 0.42) inset;
}


.card-title {
    text-align: center;
    color: #f7f7f7;
    opacity: 1;
    font-family: "Special Elite", serif;
    padding-top:5px;
    transform: rotate(0deg);
    font-size: 26pt;
    opacity: 1;
    background: rgba(0,0,0,0.4);
    position: relative;
    top: 2vh;
}
.bg-map .card-title {
    background: rgba(255, 255, 255, 0.13);
}
.coming-soon {
    font-family: "Special Elite", serif;
    font-size: 32pt;
    color: #f7f7f7;
}
.bg-forum .coming-soon {
    transform: rotate(-20deg);
    position: relative;
    left: 6vh;
    top: 6vh;
    text-align: center;
}

.bg-shop .coming-soon {
    transform: rotate(18deg);
    position: relative;
    top: 10vh;
    opacity: 1;
}

.invited-image .invite-form {
    width: 250px;
    position: relative;
    left: 30vw;
    text-align: center;
    background: #f7f7f7;
    padding: 10px;
    border: inset 1px rgba(238, 238, 238, 0.72);
    box-shadow: 12px 5px 10px rgba(26, 26, 26, 0.35);
}

.invited-image .invite-form input {
    width: 50%;
    margin: 0 auto 1vh;
}
@media only screen and (max-width: 1024px) {

    .invited-image .invite-form {
        position: relative;
        left: 15vw;
        top: 2vh;
    }

}