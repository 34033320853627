@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Special+Elite&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 11pt;
  color: #292879;
  background: #b4b4fc;
  width:100vw;
  height:100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-fc {
  background-color: #1B1B3FFF;
  border-bottom: 3px solid #13132d;
  margin-bottom: 2vh;
}

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}